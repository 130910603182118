import React from 'react';
import SEO from '../components/seo';
import { Layout as AntLayout, Row, Col } from 'antd';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';
import Jeremy from '../components/images/Jeremy';
import Kelly from '../components/images/Kelly';
import Fong from '../components/images/Fong';
import Jason from '../components/images/Jason';
import Zhibek from '../components/images/Zhibek';
import Monty from '../components/images/Monty';
import Paige from '../components/images/Paige';
import Ana from '../components/images/Ana';
import Desk from '../components/images/desk';

const { Content } = AntLayout;

const Team = [
  {
    name: 'Jeremy Battles',
    role: 'President and Principal',
    image: <Jeremy />,
    description: 'As Principal for IRC, Jeremy leads the firm’s talented staff and serves as the main liaison ensuring quality and efficacy for the firm’s diverse portfolio of clients. Jeremy joined IRC in 2001, became a partner in 2004, and has been the firm’s President since 2022. Jeremy is a Chartered Property & Casualty Underwriter (CPCU) and has vast experience in both P&C and A&H lines of insurance, with a special focus on the more complex liability claims-made products. Jeremy also co-founded and is the CEO of FilingRamp ' + '<a href="www.filingramp.com">(www.filingramp.com)</a>' + ', a software company that is reinventing how the insurance industry does filing. Jeremy graduated from the University of Richmond, finished in the top 100 in both the New York City and Boston Marathons, and has a passion for global travel. He currently lives in Jersey City and can often be found running in Liberty State Park or enjoying dinner with his wife and children at their local taqueria.',
  },
  {
    name: 'Kelly Gunning',
    role: 'Vice President and Principal',
    image: <Kelly />,
    description: 'Kelly joined IRC in August of 2008 as a compliance analyst and moved into a project manager role in 2014. In 2022, she became a junior partner for the firm. Kelly is a Chartered Property & Casualty Underwriter (CPCU) and the lead contact for several IRC clients and projects. Kelly has experience across all P&C lines of insurance and various niche MGA programs, as well as with complex multi-line program filings, workers compensation, and bureau maintenance management. Prior to graduating from the University of Florida with degrees in Communications and Management, Kelly grew up as an “Army Brat” and still struggles with the question, “Where are you from?” After nearly 10 years in New York City and another 8 years in the Twin Cities of Minnesota, she is currently trying out North Carolina. She lives in Charlotte with her husband and two children. She enjoys chasing her kids, music, travel, gardening, and frequent trips back to the New York City area.',
  },
  {
    name: 'Fong Li',
    role: 'Vice President and Principal',
    image: <Fong />,
    description: 'Fong joined IRC in January 2010 as a compliance analyst. In 2014, he transitioned to a product manager role, and by 2022, he advanced to junior partner at IRC. Fong is a Chartered Property & Casualty Underwriter (CPCU) and possesses extensive expertise across all Property & Casualty insurance lines, including products like Aviation and Trade Credit. Fong specializes in developing Excel-based rating engines tailored for both commercial and personal insurance products, and helping carriers and MGA’s integrate these tools into their policy administration operations. Fong earned a finance degree with a minor in economics from Pace University. He grew up in the New York City area and currently resides in New Jersey. Outside of work, Fong is an avid fan of his favorite blue-and-orange baseball team and enjoys hiking to connect with nature.',
  },
  {
    name: 'Jason Graciolett',
    role: 'Manager',
    image: <Jason />,
    description: 'Jason brings over 20 years of expertise in HR administration and insurance, with a comprehensive background across all lines within the Property & Casualty space. Since joining IRC in 2006, he has demonstrated a meticulous work ethic and steadfast commitment to client service. A graduate of Montclair State University, Jason also holds the Chartered Property & Casualty Underwriter (CPCU) designation. Jason is passionate about training and mentoring junior staff while fostering growth and development within his team. He’s also a trusted resource for the executive team, providing support on strategic and marketing initiatives. Outside of work, Jason enjoys spending time with his wife and son, exploring their shared interests in music, travel, and museums.',
  },
  {
    name: 'Zhibek Bizhanova',
    role: 'Senior Analyst',
    image: <Zhibek />,
    description: 'Zhibek joined IRC in September 2024 and is currently pursuing the Chartered Property & Casualty Underwriter designation. With extensive experience in P&C insurance filings, market research, and product development from her time at Canary Consulting, Zhibek brings valuable expertise to her clients and is dedicated to delivering exceptional consulting services. She also has a strong background as a Financial Risk Manager, having worked with leading banks and insurance companies in Kazakhstan. Zhibek holds MBAs from both Wright State University and Kazakh Economic University. A lifelong learner, she is passionate about expanding her knowledge every day. She currently resides in Dayton, Ohio, where she enjoys cycling and hiking with her family in the scenic Ohio hills.',
  },
  {
    name: 'Montgomery “Monty” Stewart',
    role: 'Analyst',
    image: <Monty />,
    description: 'Monty joined IRC in the summer of 2021 and has been perfecting the subtle art of manuscript endorsement filings ever since. He proudly calls Brooklyn College his alma mater, and the neighborhoods of Bushwick, Carroll Gardens, and Sunset Park his “old stomping grounds”. (He considers the Hudson Valley to be his “even older stomping grounds”.) When Monty isn’t preparing filings at record speeds, he can be seen at one of his favorite neighborhood cafes, or riding his bike to parts unknown.',
  },
  {
    name: 'Paige Miller',
    role: 'Analyst',
    image: <Paige />,
    description: 'Paige joined in September of 2024 and is one of the newest members of the IRC team. She grew up in Sarasota, Florida and studied marketing at Florida Gulf Coast University. After a stint in DC, she now calls New York City her home. Paige’s experience in marketing and sales lends itself well to what we do here at IRC, especially when it comes to our relationships with our clients. For fun she enjoys spending time with her friends and family, traveling, baking and exploring the city.',
  },
  {
    name: 'Ana Cruz',
    role: 'Analyst',
    image: <Ana />,
    description: 'Ana joined IRC in the latter half of 2024, bringing fresh perspectives to the team. Since joining IRC, she has been focusing on circular updates and Property and Casualty filings. With 10 years of experience in customer relations, Ana brings a wealth of expertise in delivering exceptional service and ensuring operational efficiency. She is dedicated to enhancing customer satisfaction, optimizing processes, and leading cross-functional teams to successful project outcomes. A Brooklyn native, Ana holds a degree from Brooklyn College, enjoys crocheting, and loves spending time with her two cats.',
  }
]

const About = () => (
  <Layout className="about-layout">
    <SEO title="About IRC" />
    <Header theme="dark">
      <h1>We are IRC.</h1>
      <p>
        Helping companies like yours
        <br /> to reach their full potential.
      </p>
    </Header>
    <Content className="about-content">
      <Row gutter={32} type="flex">
        <Col xs={24} md={12} className="company-overview">
          <div>
            <div className="title-text">Company Overview</div>
            <p>
              Insurance Regulatory Consultants, LLC (IRC) is a full service
              state filing and regulatory compliance company which was formed in
              1997 by John Battles and Kevin Purcell. Our current staff has more
              than 100 years of insurance experience and it is that experience
              which has enabled us to develop expertise with all types of filing
              and product development projects. Since we started, our staff has
              produced more than 30,000 submissions.
            </p>
          </div>
        </Col>
        <Col xs={0} md={12}>
          <Desk />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col className="our-team">
          <div className="title-text">Our Team</div>
          <p>
            People who not only care about the end result, but the journey
            getting there. Our path to approvals connects you to experienced
            staff who are passionate, personable, and effective.
          </p>
        </Col>
        <Col>
          <Row className="faces" gutter={32}>
            {
              Team.map(teamMember => (
                <Col
                  span={22}
                  offset={1}
                  key={teamMember.name}
                >
                  <div className="face">
                    <Row gutter={32}>
                      <Col xs={24} sm={9}>
                        { teamMember.image }
                        <div className="name">
                          { teamMember.name }
                          <span>{ teamMember.role }</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={15}>
                        <p dangerouslySetInnerHTML={{ __html: teamMember.description }} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
    </Content>
    <Footer />
  </Layout>
);

export default About;
